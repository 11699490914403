import logo from "../../assets/images/logo.png";
import loginIcon from "../../assets/icons/login-icon.png";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import RechargeContext from "../../store/recharge-context";
import LoginModal from "../ui/LoginModal";
import RegisterModal from "../ui/RegisterModal";
const RechargeHeader = () => {
  const RechargeCtx = useContext(RechargeContext);
  const [loginShow, setLoginShow] = useState(false);
  const [registerShow, setRegisterShow] = useState(false);

  useEffect(() => {
    RechargeCtx.checkForLogin();
  }, []);

  const logOutHandler = () => {
    RechargeCtx.logout();
  };
  const hideModalHandler = () => {
    setLoginShow(false);
  };

  const hideRegisterModalHandler = () => {
    setRegisterShow(false);
  };
  const openLoginFormHandler = () => {
    setLoginShow(true);
  };
  const openRegisterFormHandler = () => {
    setRegisterShow(true);
  };

  return (
    <>
      <LoginModal
        title="Login "
        show={loginShow}
        onHide={hideModalHandler}
        confirm="Login"
        cancel="Cancel"
      />
      <RegisterModal
        title="Register"
        show={registerShow}
        onHide={hideRegisterModalHandler}
        confirm="Register"
        cancel="Cancel"
      />

      <Row className="header ">
        <Col xs="6">
          <div>
            <Link to="/">
              <img
                src={logo}
                alt="Active Service Logo"
                className="fc-logo h-100"
              />
            </Link>
          </div>
        </Col>
        <Col xs="6">
          <Row className="justify-content-end align-items-center">
            <Col md="1" xs="6">
              <div className="h-100 align-items-center">
                <img
                  src={loginIcon}
                  alt="profile avatar"
                  style={{ width: "45px", marginTop: "10px" }}
                  className="profile-avatar p-1"
                />
              </div>
            </Col>
            <Col md="3" xs="6">
              {RechargeCtx.isLogin && (
                <span>
                  <Link onClick={logOutHandler}>
                    <span className="login-btn">Logout</span>
                  </Link>
                </span>
              )}
              {!RechargeCtx.isLogin && (
                <span className="login-btn">
                  <Link onClick={openLoginFormHandler}>Login</Link>/
                  <Link onClick={openRegisterFormHandler}> Register</Link>
                </span>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default RechargeHeader;
