import { Link, Outlet } from "react-router-dom";
import RechargeFooter from "./RechargeFooter";
import RechargeHeader from "./RechargeHeader";
import { Col, Row, Container } from "react-bootstrap";
import Cart from "../ui/Cart";
import rechargeIcon from "../../assets/icons/recharge-nav.png";
import homeIcon from "../../assets/icons/home.png";

const Recharge = () => {
  return (
    <>
      <Container fluid className="h-100">
        <RechargeHeader />

        <Row>
          <Col className="main-section">
            <Row className="mt-5 me-3">
              <Col md={2} className="d-none  d-md-block">
                <Cart className="justify-content-center ">
                  <Row className="recharge-nav mb-4">
                    <Col sm="3">
                      <img
                        src={homeIcon}
                        style={{ width: "20px" }}
                        className="me-3"
                      />
                    </Col>
                    <Col sm="8">
                      <Link className="h-center v-center" to={"/"}>
                        Home
                      </Link>
                    </Col>
                  </Row>
                  <Row className="recharge-nav mb-4">
                    <Col sm="3">
                      <img
                        src={rechargeIcon}
                        style={{ width: "20px" }}
                        className="me-3"
                      />
                    </Col>
                    <Col sm="8">
                      <Link
                        to={"/mobile-recharge"}
                        className="h-center v-center"
                      >
                        Mobile Recharge
                      </Link>
                    </Col>
                  </Row>
                  <Row className="recharge-nav mb-4">
                    <Col sm="3">
                      <img
                        src={rechargeIcon}
                        style={{ width: "20px" }}
                        className="me-3"
                      />
                    </Col>
                    <Col sm="8">
                      <Link
                        className="h-center v-center"
                        to={"/bundle-activation"}
                      >
                        Bundle Activation
                      </Link>
                    </Col>
                  </Row>
                  <Row className="recharge-nav mb-4">
                    <Col sm="3">
                      <img
                        src={rechargeIcon}
                        style={{ width: "20px" }}
                        className="me-3"
                      />
                    </Col>
                    <Col sm="8">
                      <Link className="h-center v-center" to={"/"}>
                        Remittance
                      </Link>
                    </Col>
                  </Row>
                </Cart>
              </Col>
              <Col md={10} className="mb-2 pb-2">
                <Cart>
                  <Outlet />
                </Cart>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <RechargeFooter />
        </Row>
      </Container>
    </>
  );
};
export default Recharge;
