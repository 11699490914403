export const fetchData = async (url) => {
  const apiUrl = `https://aict.as.ws.support.af/api/v1/${url}`;
  try {
    const response = await fetch(apiUrl);
    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(`Fetching data failed: ${error.message}`);
  }
};

export const sendData = async (url, data) => {
  const apiUrl = `https://aict.as.ws.support.af/api/v1/${url}`;
  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }
    return response;
  } catch (error) {
    return new Error(error);
  }
};
