import React, { useEffect, useState } from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { fetchData } from "../../utils/data-helpers";
import parseHtml from "../../utils/parseHtml";

const SlideShow = () => {
  const [slideshow, setSlideShow] = useState([]); // Initialize state with an empty array
  const [isLoading, setIsLoading] = useState(true); // Add a loading state

  useEffect(() => {
    const fetchDataAsync = async () => {
      try {
        const slideShowData = await fetchData("slide-shows");
        setSlideShow(slideShowData.data);
        setIsLoading(false); // Set loading to false after data is fetched
      } catch (error) {
        console.error("Error fetching slideshow data:", error);
        setIsLoading(false); // Set loading to false even if there is an error
      }
    };

    fetchDataAsync();
  }, []); // Empty dependency array ensures this runs only once

  return (
    <section
      className="services-overview content-wrapper custom-content-wrapper with-bottom-padding spacing-with-header"
      style={{ background: "#ebf2fb", marginTop: "0px" }}
      id="services-overview"
    >
      {isLoading ? (
        <p className="text-center">Loading...</p> // Show loading message while fetching data
      ) : (
        <Slide>
          {slideshow.map((slide, index) => (
            <div className="each-slide-effect" key={index}>
              <div
                style={{
                  backgroundImage: `url(${slide.url})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  height: "400px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white",
                  textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#00000080",
                    padding: "20px",
                    borderRadius: "10px",
                    width: "80%",
                    height: "80%",
                    maxWidth: "800px",
                    textAlign: "center",
                  }}
                >
                  <h2 className="slide-title" style={{ margin: "0 0 20px 0" }}>
                    {slide.title}
                  </h2>
                  {parseHtml(slide.description)}
                </div>
              </div>
            </div>
          ))}
        </Slide>
      )}
    </section>
  );
};

export default SlideShow;
