import { useContext, useEffect } from "react";
import mobileIcon from "../assets/icons/mobile_icon.svg";

import { Row, Col, Form } from "react-bootstrap";

import RechargeContext from "../store/recharge-context";
import { useNavigate } from "react-router-dom";

const ReportPage = () => {
  const navigate = useNavigate();
  const RechargeCtx = useContext(RechargeContext);

  useEffect(() => {
    if (!RechargeCtx.recharge.confirm) {
      return navigate("/mobile-recharge/summary");
    }
  }, []);

  return (
    <Row className="me-2 mt-4 mb-4">
      <Col md="8" sm="12">
        <div className="desktop-heading-image">
          <img
            className="heading-image"
            src={mobileIcon}
            title="Mobile Recharge"
            alt="Mobile Recharge"
          />
        </div>

        <div className="recharge-title">Recharge Completed!</div>
        <Row className="me-2 mt-4 mb-4">
          <Col sm="8">
            <div className="summary">
              You have recharge ( {RechargeCtx.mobileNumber.value} ) by{" "}
              {RechargeCtx.amount.value} AFN.
            </div>
          </Col>
        </Row>
      </Col>

      <Col md="4" sm="12">
        <div>
          <div className="recharge-title mb-3">Process done</div>
          <ul className="recharge-steps">
            <li className={RechargeCtx.mobileNumber.isValid ? "done" : ""}>
              Enter Correct Mobile Number
            </li>
            <li className={RechargeCtx.operator.isValid ? "done" : ""}>
              Select an Operator{" "}
            </li>
            <li className={RechargeCtx.amount.isValid ? "done" : ""}>
              Enter a Valid Amount{" "}
            </li>
            <li className={RechargeCtx.isLogin ? "done" : ""}>
              Login to account
            </li>
            <li className={RechargeCtx.recharge.confirm ? "done" : ""}>
              Recharge done
            </li>
          </ul>
        </div>
      </Col>
    </Row>
  );
};

export default ReportPage;
