import { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

function RechargeModal(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Modal show={props.show} onHide={props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <div className="summary">{props.mobileNumber}</div>
          <div className="summary">Recharge by</div>
          <div className="summary">{props.amount} AFN</div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            {props.cancel}
          </Button>
          <Button variant="primary" onClick={props.onConfirm}>
            {props.confirm}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default RechargeModal;
