import { Fragment } from "react";
import News from "../components/home/News";
import SlideShow from "../components/home/SlideShow";
import Recharge from "../components/home/Recharge";

import AboutUs from "../components/home/AboutUs";
import OurPartner from "../components/home/OurPartner";
import Blog from "../components/home/Blog";
import { Container } from "react-bootstrap";

const Home = () => {
  return (
    <>
      <div className="main-container ">
        <div className="main-wrapper">
          <div className="main-content">
            <div className="client-page-container " translate="no">
              <SlideShow />

              <Recharge />
              <AboutUs />
              {/* <News /> */}

              <Blog />

              <OurPartner />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
