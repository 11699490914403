import mobileIcon from "../assets/icons/mobile_icon.svg";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import RechargeContext from "../store/recharge-context";
import Button from "../components/ui/Button";
import RechargeModal from "../components/ui/RechargeModal";
import { useState } from "react";

import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

const SummaryPage = () => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => setShow(false);

  const RechargeCtx = useContext(RechargeContext);

  useEffect(() => {
    if (!RechargeCtx.isLogin) {
      return navigate("/mobile-recharge/rechargeLogin");
    }
  }, []);

  const confirmHandler = () => {
    console.log("confirm!!!");
  };

  const openModalHandler = () => {
    setShow(true);
  };
  const hideModalHandler = () => {
    setShow(false);
  };
  const rechargeConfirmHandler = () => {
    setShow(false);
    RechargeCtx.confirmRecharge();
    navigate("/mobile-recharge/report");
  };

  return (
    <>
      <RechargeModal
        title="Mobile Recharge Confirmation"
        amount={RechargeCtx.amount.value}
        mobileNumber={RechargeCtx.mobileNumber.value}
        show={show}
        onHide={hideModalHandler}
        confirm="Confirm"
        cancel="Cancel"
        onConfirm={rechargeConfirmHandler}
      />

      <Row className="me-2 mt-4 mb-4">
        <Col md="8" sm="12">
          <div className="desktop-heading-image">
            <img
              className="heading-image"
              src={mobileIcon}
              title="Mobile Recharge"
              alt="Mobile Recharge"
            />
          </div>

          <div className="recharge-title">Recharge Summary</div>
          <Row className="me-2 mt-4 mb-4 justify-content-center ">
            <Col sm="6" className="justify-content-center">
              <div className="summary">
                <strong>Amount: {RechargeCtx.amount.value} AFN</strong>
              </div>
              <div className="summary">
                <strong>Mobile Number: {RechargeCtx.mobileNumber.value}</strong>
              </div>
              <div className="summary">
                <strong>Operator: {RechargeCtx.operator.value} </strong>
              </div>
              <div>
                <Button type="button" onClick={openModalHandler}>
                  Continue
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default SummaryPage;
