import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import { fetchData } from "../../utils/data-helpers";

const RechargeFooter = () => {
  const [company, setCompany] = useState({});
  useEffect(() => {
    const getData = async () => {
      try {
        const companyData = await fetchData("companies");
        setCompany(companyData.data);
      } catch (err) {}
    };
    getData();
  }, []);
  return (
    <footer className="custome-footer">
      <div className="footer-section custome-footer">
        <div className="upper-section custome-footer">
          <div className="footer-content custome-footer">
            <div>
              <Row>
                <Col lg={2} md={2}>
                  <div className="footer-list">
                    <div className="links-list">
                      <h3>Active Services</h3>
                      <ul>
                        <li>
                          <Link to="/about-details">About Us</Link>
                        </li>
                        <li>
                          <Link to="/blogs">Our Blogs</Link>
                        </li>
                        <li>
                          <Link to="/contacts">Contact Us</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col lg={3} md={3}>
                  <div className="footer-list">
                    <div className="links-list">
                      <h3>Recharge&Remittance</h3>
                      <ul>
                        <li>
                          <Link to="/mobile-recharge">Mobile Recharge</Link>
                        </li>

                        <li>
                          <Link to="/bundle-activation">Bundle Activation</Link>
                        </li>
                        <li>
                          <Link to="/">Remittance</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>

                <Col lg={3} md={3}>
                  <div className="footer-list">
                    <h3>Download Active Services App</h3>

                    <div className="app-links">
                      <div className="images-list">
                        <Link to="#">
                          <img
                            className="google-play"
                            src="https://pwa-cdn.freecharge.in/pwa-static/pwa/images/footer/download-app-play-store.svg"
                            alt="google play image"
                            title="Google Play Store"
                            loading="lazy"
                          />
                        </Link>
                        <br />
                        <Link to="#">
                          <img
                            className="app-store"
                            src="https://pwa-cdn.freecharge.in/pwa-static/pwa/images/footer/download-app-ios.svg"
                            alt="app store image"
                            title="App Store"
                            loading="lazy"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col lg={3} md={3}>
                  <div className="footer-list">
                    <h3>Follow Us</h3>
                    <div className="images-list">
                      <a href={company.facebook}>
                        <img
                          src="https://pwa-cdn.freecharge.in/pwa-static/pwa/images/footer/facebook.svg"
                          alt="fb image"
                          title="Facebook"
                          loading="lazy"
                        />
                      </a>

                      <a href={company.twitter}>
                        <img
                          src="https://pwa-cdn.freecharge.in/pwa-static/pwa/images/footer/twitter.svg"
                          alt="twitter image"
                          title="Twitter"
                          loading="lazy"
                        />
                      </a>
                      <a href={company.youtube}>
                        <img
                          src="https://pwa-cdn.freecharge.in/pwa-static/pwa/images/footer/youtube.svg"
                          alt="youtube image"
                          title="Youtube"
                          loading="lazy"
                        />
                      </a>

                      <div className="mt-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-geo-alt"
                          viewBox="0 0 16 16"
                        >
                          <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10" />
                          <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                        </svg>

                        {company.location1}
                      </div>
                      <div className="mt-1">
                        <span>
                          {" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-envelope"
                            viewBox="0 0 16 16"
                          >
                            <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                          </svg>{" "}
                        </span>
                        {company.email1}
                      </div>
                      <div className="mt-1">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-phone"
                            viewBox="0 0 16 16"
                          >
                            <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                            <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
                          </svg>{" "}
                        </span>
                        {company.phone1}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <hr />
          </div>
        </div>
        <div
          className="lower-section mb-3 mt-0"
          style={{ backgroundColor: "#1d1e25" }}
        >
          © 2024 Active Services All Rights Reserved | Powered By
          <a href="https://www.aryanict.com/" target="__blank">
            Aryan ICT Solutions.
          </a>
        </div>
      </div>
    </footer>
  );
};

export default RechargeFooter;
