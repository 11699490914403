import { Row, Col } from "react-bootstrap";

import { useState, useEffect } from "react";
import { fetchData } from "../../utils/data-helpers";
const OurPartner = () => {
  const [partners, setPartners] = useState([]);
  useEffect(() => {
    const fetchDataAsync = async () => {
      try {
        const partnerData = await fetchData("partners");
        setPartners(partnerData.data);
      } catch (error) {}
    };

    fetchDataAsync();
  }, []);

  return (
    <>
      <section className="mt-5">
        <div className="sub-title">
          <h2>Our Partners</h2>
        </div>

        <div style={{ backgroundColor: "#ebf2fb", padding: "20px 10px" }}>
          <div>
            <Row className="justify-content-md-center">
              {partners.map((partner, index) => (
                <Col md="auto" sm="6" key={index}>
                  <img src={partner.url} style={{ height: "90px" }} />
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurPartner;
