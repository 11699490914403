import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { fetchData } from "../../utils/data-helpers";
import parseHtml from "../../utils/parseHtml";
const BlogDetails = () => {
  const [blog, setBlog] = useState({});
  const { id } = useParams();
  useEffect(() => {
    const fetchBlogData = async () => {
      const blogData = await fetchData(`blogs/${id}`);
      setBlog(blogData.data);
    };
    fetchBlogData();
  }, []);

  return (
    <>
      <div className="main-container ">
        <div className="main-wrapper">
          <div className="main-content">
            <div className="client-page-container " translate="no">
              <section
                className="seo-grid-section"
                style={{ background: "#ebf2fb", textAlign: "center" }}
              >
                <div className="content-wrapper">
                  <h2>{blog.title}</h2>
                  <div className="justify-conten-center">
                    <img src={blog.image} style={{ width: "50%" }} />
                  </div>

                  <div>{parseHtml(blog.description)}</div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogDetails;
