import parse from "html-react-parser";
const parseHtml = (value) => {
  if (value) {
    return <>{parse(value)}</>;
  } else {
    return null;
  }
};

export default parseHtml;
