import { Link } from "react-router-dom";
import classes from "../assets/error.module.css";
const ErrorPage = () => {
  return (
    <div className="container">
      <h1>Page not found</h1>
      <Link to={"/"}>To To Home</Link>
    </div>
  );
};

export default ErrorPage;
