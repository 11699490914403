// import './assets/main.css';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import MasterPage from "./components/layouts/MasterPage";
import ErrorPage from "./pages/ErrorPage";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/main.css";
import "./assets/recharge.css";
import "./assets/custom.css";

import "./assets/style.css";
import Home from "./pages/Home";

import Recharge from "./components/recharge/Recharge";
import MobileRecharge from "./pages/MobileRecharge";
import RechargeLoginPage from "./pages/RechargeLoginPage";

import SummaryPage from "./pages/SummaryPage";
import ReportPage from "./pages/ReportPage";
import BundleActivation from "./pages/BundleActivation";
import BlogDetails from "./pages/details/BlogDetail";
import AboutDetails from "./pages/details/AboutDetails";

import BlogList from "./pages/BlogList";
import Gallery from "./pages/Gallery";
import Contact from "./pages/Contact";

function App() {
  const routes = createBrowserRouter([
    {
      path: "/",
      element: <MasterPage />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/blog-details/:id",
          element: <BlogDetails />,
        },
        {
          path: "/about-details",
          element: <AboutDetails />,
        },
        {
          path: "/blogs",
          element: <BlogList />,
        },
        {
          path: "/gallery",
          element: <Gallery />,
        },
        {
          path: "/contacts",
          element: <Contact />,
        },
      ],
    },
    {
      path: "mobile-recharge",
      element: <Recharge />,

      children: [
        {
          path: "",
          element: <MobileRecharge />,
        },
        {
          path: "rechargeLogin",
          element: <RechargeLoginPage />,
        },
        {
          path: "summary",
          element: <SummaryPage />,
        },
        {
          path: "report",
          element: <ReportPage />,
        },
      ],
    },
    {
      path: "/bundle-activation",
      element: <Recharge />,
      children: [
        {
          path: "",
          element: <BundleActivation />,
        },
      ],
    },
  ]);

  return <RouterProvider router={routes} />;
}

export default App;
