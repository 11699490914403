import React, { useState } from "react";
import { Container, Form, Row, Col, Spinner } from "react-bootstrap";
import Cart from "../components/ui/Cart";
import Button from "../components/ui/Button";
import { sendData } from "../utils/data-helpers";

const Contact = () => {
  const [dataFeedback, setDataFeedback] = useState({
    error: false,
    success: false,
    loading: false,
  });

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [formErrors, setFormErrors] = useState({
    name: false,
    email: false,
    subject: false,
    message: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setDataFeedback({
      loading: true,
    });
    // Validate form fields
    const errors = {};
    let isValid = true;

    Object.keys(formData).forEach((key) => {
      if (formData[key].trim() === "") {
        errors[key] = true;
        isValid = false;
      } else {
        errors[key] = false;
      }
    });

    if (isValid) {
      const sendContactData = async (data) => {
        try {
          const response = await sendData("contacts", data);
          if (!response.ok) {
            throw new Error("Could not send message");
          }
          setDataFeedback({
            success: true,
            loading: false,
          });
        } catch {
          setDataFeedback({
            error: true,
            loading: false,
          });
        }
      };
      sendContactData(formData);
      setFormData({
        name: "",
        email: "",
        subject: "",
        message: "",
      });
      setFormErrors({
        name: false,
        email: false,
        subject: false,
        message: false,
      });
    } else {
      setDataFeedback({
        error: false,
        loading: false,
      });
      setFormErrors(errors);
    }
  };

  return (
    <Container className="mt-3 mb-3">
      <Cart>
        <div className="sub-title">
          <h2>Contact Us</h2>
        </div>

        <div className="text-center mb-3">
          {dataFeedback.error && (
            <span className="text-danger">Could not send your message!</span>
          )}
          {dataFeedback.success && (
            <span className="text-success">Your message sent successfully</span>
          )}
        </div>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md="6">
              <Form.Group controlId="formName" className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  isInvalid={formErrors.name}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter your name.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group controlId="formEmail" className="mb-3">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  isInvalid={formErrors.email}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a valid email address.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Form.Group controlId="formSubject" className="mb-3">
            <Form.Label>Subject</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter subject"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              isInvalid={formErrors.subject}
            />
            <Form.Control.Feedback type="invalid">
              Please enter the subject.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formMessage" className="mb-3">
            <Form.Label>Message</Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              placeholder="Enter your message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              isInvalid={formErrors.message}
            />
            <Form.Control.Feedback type="invalid">
              Please enter your message.
            </Form.Control.Feedback>
          </Form.Group>

          <Button type="submit" className="mb-3">
            {dataFeedback.loading && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}

            <span className="ms-2">Submit</span>
          </Button>
        </Form>
      </Cart>
    </Container>
  );
};

export default Contact;
