import React, { useEffect, useState } from "react";
import { Card, Button, Container, Row, Col } from "react-bootstrap";
import { fetchData } from "../utils/data-helpers";
import parseHtml from "../utils/parseHtml";
import { Link } from "react-router-dom";

const BlogList = () => {
  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    const fetchDataAsync = async () => {
      try {
        const slideShowData = await fetchData("blogs");
        setBlogs(slideShowData.data);
      } catch (error) {}
    };

    fetchDataAsync();
  }, []);
  return (
    <Container fluid className="mt-5" style={{ minHeight: "20rem" }}>
      <div className="sub-title">
        <h2>Our Blogs</h2>
      </div>
      <Row>
        {blogs.map((blog, index) => (
          <Col key={index} sm={12} md={6} lg={4} className="mb-4">
            <Card className="blog-card">
              <Card.Img
                variant="top"
                style={{ height: "200px" }}
                src={blog.image}
              />
              <Card.Body>
                <Card.Title className="blog-title">
                  <Link to={`/blog-details/${blog.id}`}>{blog.title}</Link>
                </Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  {new Date(blog.date).toLocaleDateString()}
                </Card.Subtitle>

                <div className="sub-text">{parseHtml(blog.description)}</div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default BlogList;
