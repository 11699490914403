import { Fragment, useState } from "react";

import { Container, Navbar, Nav, NavDropdown, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";

const Header = () => {
  const [showService, setShowService] = useState(false);
  const showServiceHandler = () => {
    setShowService(true);
  };
  const hideServiceHandler = () => {
    setShowService(false);
  };

  return (
    <Navbar expand="lg" className="" style={{ backgroundColor: "#ebf2fb" }}>
      <Container>
        <Navbar.Brand href="/" style={{ marginRight: "40px" }}>
          <img src={logo} alt="Active Service Logo" className="fc-logo" />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/">
              Home
            </Nav.Link>
            <Nav.Link as={Link} to="/about-details">
              About Us
            </Nav.Link>

            <NavDropdown
              title="Our Services"
              show={showService}
              onMouseEnter={showServiceHandler}
              onMouseLeave={hideServiceHandler}
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item as={Link} to="/mobile-recharge">
                Mobile Racharge
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item as={Link} to="/bundle-activation">
                Bundle Activation
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item as={Link} to="/">
                Rimittance
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={Link} to="/blogs">
              Our Blogs
            </Nav.Link>
            <Nav.Link as={Link} to="/gallery">
              Gallery
            </Nav.Link>
            <Nav.Link as={Link} to="/contacts">
              Contact Us
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
