import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import Button from "../components/ui/Button";
import { dataBundles, getOperators, voiceBundles } from "../utils/helpers";

function BundleActivation() {
  const [formData, setFormData] = useState({
    phoneNumber: "",
    operator: "",
    bundleType: "", // Added bundleType field to determine the selected bundle type
    dataBundle: "",
    voiceBundle: "",
  });

  const [errors, setErrors] = useState({});
  const operators = getOperators();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    const identifier = setTimeout(() => {
      const [opt] = operators.filter((operator) => {
        return operator.code.includes(formData?.phoneNumber[2]);
      });

      setFormData({
        ...formData,
        operator: opt?.name,
      });
    }, 500);

    return () => {
      clearTimeout(identifier);
    };
  }, [formData.phoneNumber]);

  const handleBundleTypeChange = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      bundleType: value,
      dataBundle: "", // Reset dataBundle and voiceBundle when bundle type changes
      voiceBundle: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Validate phone number before submission
    validatePhoneNumber();
    // Proceed with form submission if there are no errors
    if (!errors.phoneNumber) {
      // Handle form submission logic here
      console.log("Form data submitted:", formData);
    }
  };

  const validatePhoneNumber = () => {
    const phoneNumberRegex = /^\d{10}$/;
    if (!phoneNumberRegex.test(formData.phoneNumber)) {
      setErrors({
        ...errors,
        phoneNumber: "Phone number must be 10 digits",
      });
    } else {
      setErrors({
        ...errors,
        phoneNumber: "",
      });
    }
  };

  return (
    <Container>
      <h1 className="text-center recharge-title mb-3">Bundle Activation</h1>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>
            <Form.Group controlId="formPhoneNumber">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                onBlur={validatePhoneNumber}
                placeholder="Enter your phone number 07**"
                isInvalid={!!errors.phoneNumber}
                required
              />
              <Form.Control.Feedback type="invalid">
                {errors.phoneNumber}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formOperator">
              <Form.Label>Operator</Form.Label>
              <Form.Control
                as="select"
                name="operator"
                value={formData.operator}
                onChange={handleChange}
                required
              >
                <option value="">Select your operator</option>
                {operators.map((item) => (
                  <option key={item.name}>{item.name}</option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <Form.Group controlId="formBundleType " className="mt-3 mb-3">
          <Form.Label>Select Bundle Type</Form.Label>
          <div>
            <Form.Check
              inline
              type="radio"
              label="Data Bundle"
              name="bundleType"
              value="data"
              checked={formData.bundleType === "data"}
              onChange={handleBundleTypeChange}
            />
            <Form.Check
              inline
              type="radio"
              label="Voice Bundle"
              name="bundleType"
              value="voice"
              checked={formData.bundleType === "voice"}
              onChange={handleBundleTypeChange}
            />
          </div>
        </Form.Group>

        {formData.bundleType === "data" && (
          <Form.Group controlId="formDataBundle">
            <Form.Label>Data Bundle</Form.Label>
            <Form.Control
              as="select"
              name="dataBundle"
              value={formData.dataBundle}
              onChange={handleChange}
              required
            >
              <option value="">Select data bundle</option>

              {dataBundles().map((bundle) => (
                <option key={bundle}>{bundle}</option>
              ))}
            </Form.Control>
          </Form.Group>
        )}

        {formData.bundleType === "voice" && (
          <Form.Group controlId="formVoiceBundle">
            <Form.Label>Voice Bundle</Form.Label>
            <Form.Control
              as="select"
              name="voiceBundle"
              value={formData.voiceBundle}
              onChange={handleChange}
              required
            >
              <option value="">Select voice bundle</option>
              {voiceBundles().map((bundle) => (
                <option key={bundle}>{bundle}</option>
              ))}
            </Form.Control>
          </Form.Group>
        )}

        <Button className="mt-3 mb-3">Activate Bundle</Button>
      </Form>
    </Container>
  );
}

export default BundleActivation;
