import recharge from "../../assets/icons/recharge.svg";
import remittance from "../../assets/icons/remittance.svg";
import budle_activation from "../../assets/icons/budle_activation.svg";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const Recharge = () => {
  return (
    <>
      <section
        className="seo-grid-section"
        style={{ background: "#ebf2fb", textAlign: "center" }}
      >
        <div className="content-wrapper">
          <h2>Recharges &Remittance</h2>
          <p>
            With Active Services, it's easy to make your family and friends
            happy! It takes only seconds to top up their mobiles back home. Fast
            and secure mobile recharges.
          </p>
          <Row className="justify-content-center">
            <Col md={2} lg={2} sm={8} xs={8}>
              <div className="recharge">
                <Link to="/mobile-recharge">
                  <img src={recharge} alt="Mobile recharge" loading="lazy" />
                  <label>Mobile Recharge</label>
                </Link>
              </div>
            </Col>
            <Col md={2} lg={2} sm={8} xs={8}>
              <div className="recharge">
                <Link to="/bundle-activation">
                  <img
                    src={budle_activation}
                    alt="Bundle Activation"
                    loading="lazy"
                  />
                  <label>Bundle Activation</label>
                </Link>
              </div>
            </Col>
            <Col md={2} lg={2} sm={8} xs={8}>
              <div className="recharge">
                <img src={remittance} alt="Remittance" loading="lazy" />
                <label>Remittance</label>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default Recharge;
