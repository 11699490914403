import mtn from "../assets/partners/mtn.jpg";
import awcc from "../assets/partners/afghan-wireless.png";
import salaam from "../assets/partners/salaam.png";
import etisalat from "../assets/partners/etisalat.png";
import roshan from "../assets/partners/roshan.png";

export const getOperators = () => {
  return [
    { name: "MTN", logo: mtn, code: ["7", "6"], perfix: "077" },
    { name: "AWCC", logo: awcc, code: ["0", "1"], perfix: "070" },
    { name: "Roshan", logo: roshan, code: ["9", "2"], perfix: "079" },
    { name: "Etisalat", logo: etisalat, code: ["8", "3"], perfix: "078" },
    { name: "Salaam", logo: salaam, code: ["4"], perfix: "074" },
  ];
};

export const isMobileNumberValid = (mobileNumber) => {
  if (
    (mobileNumber[0] === "0" &&
      mobileNumber[1] === "7" &&
      mobileNumber.length === 10) ||
    mobileNumber.length === 0
  ) {
    return true;
  } else {
    return false;
  }
};

export const dataBundles = () => {
  return ["Hourly", "Daily ", "Weekly ", "Monthly", "Social", "Nightly"];
};

export const voiceBundles = () => {
  return ["Hourly", "Daily ", "Weekly ", "Monthly"];
};
