import React, { useState } from "react";
const RechargeContext = React.createContext({
  mobileNumber: {
    value: "",
    isValid: false,
  },
  account: {
    isValid: false,
    email: "",
    password: "",
  },
  registerData: {
    firstName: {},
    lastName: "",
    mobileNumber: "",
    email: "",
    password: "",
    confirmPassword: "",
  },
  isLogin: false,

  amount: {
    value: 0,
    isValid: false,
  },
  operator: {
    value: "",
    isValid: false,
  },
  recharge: {
    confirm: false,
  },
  showAccountError: false,
  addMobileNumber: () => {},
  addAmount: () => {},
  addOperator: () => {},
  login: () => {},
  logout: () => {},
  checkForLogin: () => {},
});

export const RechargeContextProvider = (props) => {
  const [amount, setAmount] = useState("");
  const [isValidAmount, setIssValidAmount] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [isValidMobile, setIsValidMobile] = useState(false);
  const [operator, setOperator] = useState("");
  const [account, setAccount] = useState("");
  const [isLogin, setIsLogin] = useState(false);
  const [isValidOperator, setIsValidOperator] = useState(false);
  const [showAccountError, setShowAccountError] = useState(false);
  const [rechargeConfirm, setRechargeConfirm] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [userConformPassword, setUserConformPasssword] = useState("");

  const addMobileNumberHandler = (value, isValid) => {
    setMobileNumber(value);
    setIsValidMobile(isValid);
  };
  const addAmountHandler = (value, isValid) => {
    setAmount(value);
    setIssValidAmount(isValid);
  };
  const addOperatorHandler = (value, isValid) => {
    setOperator(value);
    setIsValidOperator(isValid);
  };

  const confirmRechargeHandler = () => {
    setRechargeConfirm(true);
  };

  const logOutHandler = () => {
    localStorage.removeItem("isLogin");
    setIsLogin(false);
    setAccount({
      isValid: false,
      email: "",
      password: "",
    });
  };

  const checkLoginHandler = () => {
    if (localStorage.getItem("isLogin")) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  };

  const loginHandler = (loginData) => {
    if (loginData.email.includes("@") && loginData.password.length >= 5) {
      setAccount({
        isValid: true,
        email: loginData.email,
        password: loginData.password,
      });
      setIsLogin(true);
      setShowAccountError(false);
      localStorage.setItem("isLogin", true);
    } else {
      setIsLogin(false);
      setShowAccountError(true);
    }
  };

  const registerHandler = (registerData) => {
    setUserEmail(registerData.email);
    setUserFirstName(registerData.firstName);
    setUserLastName(registerData.lastName);
    setUserPassword(registerData.password);
    setUserConformPasssword(registerData.confirmPassword);
    setUserPhoneNumber(registerData.phoneNumber);
  };

  return (
    <RechargeContext.Provider
      value={{
        mobileNumber: {
          value: mobileNumber,
          isValid: isValidMobile,
        },
        account: {
          isValid: account.isValid,
          email: account.email,
          password: account.password,
        },
        registerData: {
          firstName: userFirstName,
          lastName: userLastName,
          mobileNumber: userPhoneNumber,
          email: userEmail,
          password: userPassword,
          confirmPassword: userConformPassword,
        },
        isLogin: isLogin,
        showAccountError: showAccountError,
        amount: {
          value: amount,
          isValid: isValidAmount,
        },
        operator: {
          value: operator,
          isValid: isValidOperator,
        },
        recharge: {
          confirm: rechargeConfirm,
        },
        addMobileNumber: addMobileNumberHandler,
        addAmount: addAmountHandler,
        addOperator: addOperatorHandler,
        register: registerHandler,
        login: loginHandler,
        confirmRecharge: confirmRechargeHandler,
        logout: logOutHandler,
        checkForLogin: checkLoginHandler,
      }}
    >
      {props.children}
    </RechargeContext.Provider>
  );
};

export default RechargeContext;
