// src/components/Gallery.js
import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Modal } from "react-bootstrap";
import { fetchData } from "../utils/data-helpers";

const Gallery = () => {
  const [show, setShow] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = (image) => {
    setSelectedImage(image);
    setShow(true);
  };

  const [images, setImage] = useState([]);
  useEffect(() => {
    const fetchDataAsync = async () => {
      try {
        const recievedData = await fetchData("galleries");
        setImage(recievedData.data);
      } catch (error) {}
    };

    fetchDataAsync();
  }, []);
  return (
    <Container fluid className="mt-5">
      <div className="sub-title">
        <h2>Our Gallery</h2>
      </div>
      <Row>
        {images.map((image, index) => (
          <Col xs={12} md={3} lg={3} key={index} className="mb-4">
            <Card onClick={() => handleShow(image)}>
              <Card.Img
                className="pointer"
                variant="top"
                src={image.url}
                alt={image.title}
              />
              <Card.Body>
                <Card.Title>{image.title}</Card.Title>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

      <Modal show={show} onHide={handleClose} centered>
        {selectedImage && (
          <>
            <Modal.Header closeButton>
              <Modal.Title>{selectedImage.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <img
                src={selectedImage.url}
                alt={selectedImage.title}
                className="img-fluid"
              />
            </Modal.Body>
          </>
        )}
      </Modal>
    </Container>
  );
};

export default Gallery;
