import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { fetchData } from "../../utils/data-helpers";

import parseHtml from "../../utils/parseHtml";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";

const Blog = () => {
  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    const fetchDataAsync = async () => {
      try {
        const slideShowData = await fetchData("blogs");
        setBlogs(slideShowData.data);
      } catch (error) {}
    };

    fetchDataAsync();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Container
        fluid
        style={{ marginTop: "10px", marginBottom: "40px", width: "98%" }}
        className="justify-content-center"
      >
        <div className="sub-title">
          <h2>Our Blogs</h2>
        </div>

        <Slider {...settings}>
          {blogs.map((blog, key) => (
            <div className="item" key={key}>
              <img src={blog.image} alt={blog.title} className="card-image" />
              <div className="mt-2">
                <h2 className="blog-title text-center">
                  <Link to={`/blog-details/${blog.id}`} className="hover">
                    {blog.title}
                  </Link>
                </h2>
                <div className="mt-2 text-left text-justify">
                  {parseHtml(blog.description)}
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </Container>
    </>
  );
};

export default Blog;
