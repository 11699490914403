import { useContext, useEffect, useRef, useState } from "react";
import mobileIcon from "../assets/icons/mobile_icon.svg";

import { Row, Col, Form } from "react-bootstrap";

import RechargeContext from "../store/recharge-context";
import Button from "../components/ui/Button";
import { useNavigate } from "react-router-dom";

const RechargeLoginPage = () => {
  const navigate = useNavigate();
  const RechargeCtx = useContext(RechargeContext);
  const emailRef = useRef();
  const passwordRef = useRef();

  useEffect(() => {
    if (!RechargeCtx.mobileNumber.isValid) {
      return navigate("/mobile-recharge");
    }
    if (RechargeCtx.isLogin) {
      return navigate("/mobile-recharge/summary");
    }
  }, []);

  const submitFormHandler = (event) => {
    event.preventDefault();
    let loginData = {
      email: emailRef.current.value,
      password: passwordRef.current.value,
    };
    RechargeCtx.login(loginData);
  };
  useEffect(() => {
    if (RechargeCtx.isLogin) {
      return navigate("/mobile-recharge/summary");
    }
  }, [RechargeCtx.isLogin]);

  return (
    <Row className="me-2 mt-4 mb-4">
      <Col md="8" sm="12">
        <div className="desktop-heading-image">
          <img
            className="heading-image"
            src={mobileIcon}
            title="Mobile Recharge"
            alt="Mobile Recharge"
          />
        </div>

        <div className="recharge-title">Login To Your Account</div>
        <Row className="me-2 mt-4 mb-4">
          <Col sm="8">
            <Form onSubmit={submitFormHandler}>
              <Form.Group className="mb-3" controlId="formBasicNumber">
                <Form.Control
                  type="email"
                  size="lg"
                  placeholder="Enter Email Address"
                  ref={emailRef}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicOperatory">
                <Form.Control
                  size="lg"
                  type="password"
                  placeholder="Enter Password"
                  ref={passwordRef}
                />

                {RechargeCtx.showAccountError && (
                  <Form.Text className="text-danger">
                    Your email address or password is invalid!
                  </Form.Text>
                )}
              </Form.Group>

              <Button type="submit">Login</Button>
            </Form>
          </Col>
        </Row>
      </Col>

      <Col md="4" sm="12">
        <div>
          <div className="recharge-title mb-3">Login to process</div>
          <ul className="recharge-steps">
            <li className={RechargeCtx.mobileNumber.isValid ? "done" : ""}>
              Enter Correct Mobile Number
            </li>
            <li className={RechargeCtx.operator.isValid ? "done" : ""}>
              Select an Operator{" "}
            </li>
            <li className={RechargeCtx.amount.isValid ? "done" : ""}>
              Enter a Valid Amount{" "}
            </li>
            <li className={RechargeCtx.isLogin ? "done" : ""}>
              Login to account
            </li>
          </ul>
        </div>
      </Col>
    </Row>
  );
};

export default RechargeLoginPage;
