import classes from "./button.module.css";
const Button = (props) => {
  const btnClass = `${props.className} ${classes.button}`;
  return (
    <button className={btnClass} onClick={props.onClick} type={props.type}>
      {props.children}
    </button>
  );
};

export default Button;
