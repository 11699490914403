import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { fetchData } from "../../utils/data-helpers";
import parseHtml from "../../utils/parseHtml";
import { Link } from "react-router-dom";

const AboutUs = () => {
  const [aboutUs, setAboutUs] = useState({});
  const [aboutCategories, setAboutCategory] = useState([]);

  useEffect(() => {
    const fetchAbout = async () => {
      try {
        const aboutData = await fetchData("about-us");
        setAboutUs(aboutData.data);
      } catch (err) {}
    };

    const fetchAboutCategory = async () => {
      try {
        const aboutCategoryData = await fetchData("about-categories");
        setAboutCategory(aboutCategoryData.data);
      } catch (err) {}
    };

    fetchAbout();
    fetchAboutCategory();
  }, []);

  return (
    <section
      className="services-overview content-wrapper with-bottom-padding"
      style={{ background: "#fff", marginTop: "80px" }}
      id="services-overview"
    >
      <div className="services-container layoutId-1 multiple">
        <div className="service with-margin" style={{ background: "#fff3e9" }}>
          <div className="content">
            <div className="about-title">
              <h3>
                <Link to="/about-details">{aboutUs.title}</Link>
              </h3>
            </div>
            <div className="sub-text">{parseHtml(aboutUs.description)}</div>
          </div>
        </div>
        <div className="service with-margin" style={{ background: "#e3f0ea" }}>
          <ul className="service-features">
            {aboutCategories.map((aboutCategory, index) => (
              <li key={index}>
                {aboutCategory.title}
                <span style={{ display: "inline-block" }}>
                  {parseHtml(aboutCategory.description)}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
