import { Fragment } from "react";
import Header from "./Header";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";

const MasterPage = () => {
  return (
    <div className="main revamped-container client-page-wrapper  homepage container-fluid ">
      <Header />

      <Outlet />

      <Footer />
      <div className="pop-up-wrapper swipe-bottom secondary-layout">
        <div
          className="pop-up"
          style={{
            maxWidth: "909px",
            maxHeight: "720px",
            minWidth: "909px",
            minHeight: "475px",
          }}
        ></div>
      </div>
    </div>
  );
};

export default MasterPage;
