import { useContext, useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import RechargeContext from "../../store/recharge-context";

function LoginModal(props) {
  const RechargeCtx = useContext(RechargeContext);
  const emailRef = useRef();
  const passwordRef = useRef();
  const loginHandler = () => {
    const loginData = {
      email: emailRef.current.value,
      password: passwordRef.current.value,
    };
    RechargeCtx.login(loginData);
  };
  useEffect(() => {
    if (RechargeCtx.isLogin) {
      props.onHide();
    }
  }, [RechargeCtx.isLogin]);

  return (
    <>
      <Modal show={props.show} onHide={props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="loginForm" onSubmit={loginHandler}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                ref={emailRef}
                placeholder="name@example.com"
                autoFocus
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                ref={passwordRef}
                type="password"
                placeholder="Enter your password"
              />
            </Form.Group>
            {RechargeCtx.showAccountError && (
              <Form.Text className="text-danger">
                Your email address or password is invalid!
              </Form.Text>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            {props.cancel}
          </Button>
          <Button form="LoginForm" variant="primary" onClick={loginHandler}>
            {props.confirm}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default LoginModal;
