import { useNavigate } from "react-router-dom";

import { Row, Col, Form } from "react-bootstrap";

import mobileIcon from "../assets/icons/mobile_icon.svg";

import { useContext, useEffect, useReducer, useState } from "react";

import Button from "../components/ui/Button";
import RechargeContext from "../store/recharge-context";
import { getOperators, isMobileNumberValid } from "../utils/helpers";

const amountReducer = (state, action) => {
  if (action.type === "USER_INPUT") {
    return {
      value: action.value,
      isValid: action.value >= 50 && action.value.toString()[0] !== "0",
    };
  }
};
const MobileRecharge = () => {
  const RechargeCtx = useContext(RechargeContext);

  const [mobileNumber, setMobileNumber] = useState("");
  const [isValidMobile, setIsValidMobile] = useState(false);
  const [selectedOperator, setOperator] = useState();
  const [selectedLogo, setLogo] = useState();
  const [mobileDone, setMobileDone] = useState();
  const [amountDone, setAmountDone] = useState();
  const navigate = useNavigate();

  const [amount, amountDispatch] = useReducer(amountReducer, {
    value: 0,
    isValid: true,
  });

  const setAmountHandler = (event) => {
    amountDispatch({
      type: "USER_INPUT",
      value: event.target.value,
    });
  };

  const operators = getOperators();

  const mobileNumberHandler = (event) => {
    setMobileNumber(event.target.value);
  };

  const blurMobileNumberHandler = () => {
    setIsValidMobile(isMobileNumberValid(mobileNumber));
  };

  useEffect(() => {
    const identifier = setTimeout(() => {
      const [opt] = operators.filter((operator) => {
        return operator.code.includes(mobileNumber[2]);
      });

      setOperator(opt?.name);
      setLogo(opt?.logo);
    }, 500);

    return () => {
      clearTimeout(identifier);
    };
  }, [mobileNumber]);

  const changeOperatorHandler = (event) => {
    if (event.target.value !== "0") {
      const operator = event.target.value;
      const opt = operators.find((item) => {
        return item.name === operator;
      });

      setLogo(opt?.logo);
      setMobileNumber(opt?.perfix);
    }
  };

  const submitFormHandler = (event) => {
    event.preventDefault();
    if (isValidMobile && selectedOperator && amountDone) {
      RechargeCtx.addMobileNumber(mobileNumber, isValidMobile);
      RechargeCtx.addAmount(amount.value, true);
      RechargeCtx.addOperator(selectedOperator, true);

      navigate("rechargeLogin");
    }
    return false;
  };

  useEffect(() => {
    setMobileDone(isValidMobile && mobileNumber !== "");
  }, [mobileNumber, isValidMobile]);

  useEffect(() => {
    setAmountDone(amount.isValid && amount.value != "");
  }, [amount.isValid, amount.value]);

  return (
    <>
      <Row>
        <Col md="8" sm="12">
          <div className="desktop-heading-image">
            <img
              className="heading-image"
              src={mobileIcon}
              title="Mobile Recharge"
              alt="Mobile Recharge"
            />
          </div>

          <div className="recharge-title">Mobile Recharge</div>
          <Row className="me-2 mt-4 mb-4">
            <Col sm="8">
              <Form onSubmit={submitFormHandler}>
                <Form.Group className="mb-3" controlId="formBasicNumber">
                  <Form.Control
                    type="text"
                    size="lg"
                    placeholder="Enter Mobile Number *07"
                    value={mobileNumber}
                    onChange={mobileNumberHandler}
                    onBlur={blurMobileNumberHandler}
                    className={
                      !isValidMobile && mobileNumber !== ""
                        ? "border-danger"
                        : ""
                    }
                  />

                  {!isValidMobile && mobileNumber !== "" && (
                    <Form.Text className="text-danger">
                      Please Enter Valid Mobile Number
                    </Form.Text>
                  )}
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="formBasicOperator"
                  as={Row}
                >
                  <Col sm="7">
                    <Form.Select
                      size="lg"
                      className="operator"
                      value={selectedOperator}
                      onChange={changeOperatorHandler}
                    >
                      <option value="0">-- Operator --</option>
                      {operators.map((item) => (
                        <option key={item.name}>{item.name}</option>
                      ))}
                    </Form.Select>
                  </Col>
                  <Col sm="5">
                    {selectedLogo && (
                      <Form.Label column sm="2" className="v-center ">
                        <img src={selectedLogo} style={{ height: "35px" }} />
                      </Form.Label>
                    )}
                  </Col>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicOperatory">
                  <Form.Control
                    size="lg"
                    type="number"
                    placeholder="Enter Amount AFN"
                    min="0"
                    onChange={setAmountHandler}
                    className={!amount.isValid && "border-danger"}
                  />

                  {!amount.isValid && (
                    <Form.Text className="text-danger">
                      Please Enter Valid Amount (The amount must be equal to or
                      greater than 50)
                    </Form.Text>
                  )}
                </Form.Group>

                <Button type="submit">NEXT</Button>
              </Form>
            </Col>
          </Row>
        </Col>
        <Col md="4" sm="12">
          <div>
            <div className="recharge-title mb-3">Recharge Mobile Steps</div>
            <ul className="recharge-steps">
              <li className={mobileDone ? "done" : ""}>
                Enter Correct Mobile Number{" "}
              </li>
              <li className={selectedOperator ? "done" : ""}>
                Select an Operator{" "}
              </li>
              <li className={amountDone ? "done" : ""}>
                Enter a Valid Amount{" "}
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default MobileRecharge;
