import { useContext, useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import RechargeContext from "../../store/recharge-context";
import { Col, Row } from "react-bootstrap";

function RegisterModal(props) {
  const RechargeCtx = useContext(RechargeContext);
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const emailRef = useRef();
  const phoneNumberRef = useRef();
  const passwordRef = useRef();
  const confirmPasswordRef = useRef();

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    if (!firstNameRef.current.value) {
      newErrors.firstName = "First Name is required";
    }
    if (!lastNameRef.current.value) {
      newErrors.lastName = "Last Name is required";
    }
    if (
      !emailRef.current.value ||
      !/\S+@\S+\.\S+/.test(emailRef.current.value)
    ) {
      newErrors.email = "Valid email is required";
    }
    if (!passwordRef.current.value) {
      newErrors.password = "Password is required";
    }
    if (passwordRef.current.value !== confirmPasswordRef.current.value) {
      newErrors.confirmPassword = "Passwords do not match";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const registerHandler = (e) => {
    e.preventDefault();
    if (validateForm()) {
      // Handle successful validation and form submission
      const registerData = {
        email: emailRef.current.value,
        password: passwordRef.current.value,
        confirmPassword: confirmPasswordRef.current.value,
        firstName: firstNameRef.current.value,
        lastName: lastNameRef.current.value,
        phoneNumber: phoneNumberRef.current.value,
      };
      RechargeCtx.register(registerData);
      const loginData = {
        email: emailRef.current.value,
        password: passwordRef.current.value,
      };
      RechargeCtx.login(loginData);
    }
  };

  useEffect(() => {
    if (RechargeCtx.isLogin) {
      props.onHide();
    }
  }, [RechargeCtx.isLogin]);
  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form id="loginForm" onSubmit={registerHandler}>
          <Row>
            <Col sm="6">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  ref={firstNameRef}
                  placeholder="Your First Name"
                  autoFocus
                />
                {errors.firstName && (
                  <Form.Text className="text-danger">
                    {errors.firstName}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>

            <Col sm="6">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  ref={lastNameRef}
                  placeholder="Your Last Name"
                />
                {errors.lastName && (
                  <Form.Text className="text-danger">
                    {errors.lastName}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              ref={emailRef}
              placeholder="name@example.com"
            />
            {errors.email && (
              <Form.Text className="text-danger">{errors.email}</Form.Text>
            )}
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              type="text"
              ref={phoneNumberRef}
              placeholder="Your Phone Number"
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Password</Form.Label>
            <Form.Control
              ref={passwordRef}
              type="password"
              placeholder="Enter your password"
            />
            {errors.password && (
              <Form.Text className="text-danger">{errors.password}</Form.Text>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              ref={confirmPasswordRef}
              type="password"
              placeholder="Confirm Your Password"
            />
            {errors.confirmPassword && (
              <Form.Text className="text-danger">
                {errors.confirmPassword}
              </Form.Text>
            )}
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          {props.cancel}
        </Button>
        <Button form="loginForm" type="submit" variant="primary">
          {props.confirm}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default RegisterModal;
